/* eslint-disable @typescript-eslint/no-empty-function */
import Select from '@rio-cloud/rio-uikit/Select';
import { FormattedMessage } from 'react-intl';

export const DummyCountrySelectLatam = ({ disabled }: { disabled: boolean }) => {
    return (
        <div className={'form-group'}>
            <div className={'col-xs-12 margin-top-5 margin-bottom-5'}>
                <div className={'form-group'}>
                    <label className={'control-label'}>
                        <FormattedMessage id={'registration.companyData.countryCode'} />*
                    </label>
                    <Select
                        placeholder={''}
                        disabled={disabled}
                        options={[
                            {
                                id: 'BR',
                                label: <FormattedMessage id={'registration.countrycode.BR'} />,
                                selected: true,
                            },
                        ]}
                        onChange={() => {}}
                    />
                </div>
            </div>
        </div>
    );
};
