/* eslint-disable import/no-named-as-default */
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import AutoSuggest from '@rio-cloud/rio-uikit/AutoSuggest';
import {
    clearCitySuggestions,
    companyInfoSelector,
    selectCitySuggestions,
    setCityFields,
    touchCompanyInfoField,
} from '../companyInfoSlice';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { isAppInMaintenanceMode } from '../../../configuration/tmpSlice';

interface LatamCitySelectProps {
    width: string;
    required?: boolean;
}

export const LatamCitySelect = (props: LatamCitySelectProps) => {
    const { citySuggestions, city, cityIdValid, cityValid, cityTouched, disabled } = useAppSelector((state) => ({
        citySuggestions: selectCitySuggestions(state),
        city: companyInfoSelector(state).city,
        cityIdValid: state.companyInfo.cityIdValid ? state.companyInfo.cityIdValid : false,
        cityValid: state.companyInfo.cityValid ? state.companyInfo.cityValid : false,
        cityTouched: state.companyInfo.cityTouched ? state.companyInfo.cityTouched : false,
        disabled: isAppInMaintenanceMode(state),
    }));
    const dispatch = useDispatch();
    const { touchCity, setCompanyCity, clearSuggestions } = {
        touchCity: () => {
            dispatch(touchCompanyInfoField('city'));
        },
        setCompanyCity: (city: string, cityId: string, state: string) => {
            dispatch(setCityFields({ city, cityId, state }));
        },
        clearSuggestions: () => {
            dispatch(clearCitySuggestions());
        },
    };

    return (
        <div className={'col-xs-12 ' + props.width}>
            <div
                className={classNames({
                    'form-group': true,
                    'has-error has-feedback': cityTouched && (!cityValid || !cityIdValid),
                })}
            >
                <label className={'control-label'}>
                    <FormattedMessage id={'registration.companyData.city'} />
                    {props.required ? '*' : null}
                </label>
                <div className={'position-relative'}>
                    <AutoSuggest
                        inputProps={{
                            disabled: disabled,
                            className: 'margin-top--2',
                            placeholder: '',
                            value: city,
                            onBlur: () => touchCity(),
                            onClear: clearSuggestions,
                        }}
                        suggestions={citySuggestions.map((it) => ({
                            label: it.id.toString(),
                            id: it.id,
                            nome: it.nome,
                            uf_sigla: it.uf.sigla,
                        }))}
                        noItemMessage={'This does not look like anything to me'}
                        onSuggestionsFetchRequested={(evt: any) => {
                            const value = evt.value;
                            if (value === undefined) {
                                // ignore
                                return;
                            }

                            // update store if value is changed
                            if (city !== value) {
                                setCompanyCity(value, '', '');
                            }
                        }}
                        // onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        onSuggestionSelected={(evt: any, suggestion: any) => {
                            const city = suggestion.suggestion;
                            setCompanyCity(city.nome, String(city.id), city.uf_sigla);
                        }}
                        // onSuggestionsClearRequested={() => clearSuggestions()}
                        // onSuggestionHighlighted={this.onSuggestionHighlighted}
                        renderSuggestion={(sug: any) => <span>{`${sug.nome} (${sug.uf_sigla})`}</span>}
                    />
                    {cityTouched && !cityValid ? (
                        <span className={'form-control-feedback rioglyph rioglyph-warning-sign'} />
                    ) : null}
                </div>
                {cityTouched && (!cityValid || !cityIdValid) ? (
                    <div className={'help-block'}>
                        <FormattedMessage id={'registration.error.cityId.mustBeValidCity'} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
